import React from 'react'
import { Route,Router, Routes } from 'react-router-dom'
import Home from "../src/Component/Home"
import AboutContent from './Component/AboutContent'

function App() {
  return (
    <div>
     <Routes>
      <Route path='/' element={<Home/>}/>
      {/* <Route path='/about' element={<AboutContent/>}/> */}

     </Routes>
    </div>
  )
}

export default App
