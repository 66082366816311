import img2 from "./Images/Add a heading (1).png";
import { GrMail } from "react-icons/gr";
import { AiFillPhone } from "react-icons/ai";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import imgcul from "./Images/culture1.jpg";
import imgculv from "./Images/culture-val.jpg";
import imgculm from "./Images/culture-miss.jpg";
import imgLinkdin from "./Images/th.jpg";
import emailjs from "emailjs-com";
import Modal from "react-modal";

// import Router from ""

import "../App.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import PopupModal from "./PopupModel";
function Home() {
  const router = useNavigate();

  const [modalIsOpen, setModalIsOpen] = useState(true);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    companyName: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    const { name, email, companyName, message } = formData;

    // Create a mailto link with pre-filled data
    const mailtoLink = `mailto:ca.jaysanghvi24@gmail.com?subject=New Inquiry&body=Name: ${name}%0D%0AEmail: ${email}%0D%0ACompany Name: ${companyName}%0D%0AMessage: ${message}`;

    // Open the user's default email client
    window.location.href = mailtoLink;
  };

  const location = useLocation();
  const pathname = location.pathname;

  return (
    <div className="App">
    <PopupModal/>

     

      <main id="main">
        <section id="hero" className="hero">
          <img src={img2} alt="" data-aos="fade-in" />

          <div className="container">
            <div className="row">
              <div className="col-lg-10">
                <div className="home-secction">
                  <h2 data-aos="fade-up" data-aos-delay="100">
                    Jay Sanghvi & Associates
                  </h2>
                  <p data-aos="fade-up" data-aos-delay="200">
                    At Jay Sanghvi & Associates, we're more than service
                    providers. We're dedicated partners for your business's
                    financial well-being. We handle various financial tasks like
                    record-keeping, accounting, payroll, audits, and provide
                    guidance on taxes, GST, and strategic decisions. As a
                    respected CA firm in Ahmedabad, we prioritize transparency
                    and professionalism in the companies we work with, and we
                    have a knack for assisting startups on their journey to
                    success.
                  </p>
                </div>

                {/* <div className="col-lg-5">
            <form action="#" className="sign-up-form d-flex" data-aos="fade-up" data-aos-delay="300">
              <input type="text" className="form-control" placeholder="Enter email address"/>
              <input type="submit" className="btn btn-primary" value="Sign up"/>
            </form>
          </div> */}
              </div>
              <div className="home-button">
                <button
                  className="btn-getstarted"
                  style={{ backgroundColor: "white" }}
                  href="index.html#about"
                >
                  Book Meeting
                </button>
              </div>
            </div>
          </div>
        </section>

        <section id="about" className="about">
          <div className="container" data-aos="fade-up" data-aos-delay="100">
            <div className="row align-items-xl-center gy-5">
              {/* <div className="container-block align-items-xl-center gy-5"> */}
              <div className="col-xl-12 content">
                {/* <div className="content"> */}
                <h3 style={{ color: "#004AAD" }}>About Us</h3>
                <h2>Jay Sanghvi & Associates</h2>
                <p>
                  We are trusted professionals with high degree of ethics
                  providing services in the field of tax and audit and other
                  statutory requirements applicable to the business segment of
                  the society. Our mission is to diversify from traditional
                  practice of tax and audit regulatory requirements to
                  comprehensive and active support to the business enterprises
                  by providing wide range of advisory functions, management
                  support services to strengthen and optimize productivity and
                  profitability in the clients business with full compliance of
                  business laws and regulatory frame work.

                  The success of our profession as a CA consultant in Ahmedabad is due to our clients and the confidence & trust they have placed on us. We believe in supplying timely service to our clients. We are the best chartered accountant in Ahmedabad and our relation with our clients is based on ethical values, professional excellence and confidentiality.
                </p>
                <Link
                  style={{ backgroundColor: "#004AAD" }}
                  to="/about"
                  className="read-more"
                >
                  <span>Read More</span>
                  <i className="bi bi-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section id="stats" className="stats">
          <img src={img2} alt="" data-aos="fade-in" />

          <div
            className="container position-relative"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="row gy-4">
              <div className="col-lg-3 col-md-6">
                <div className="stats-item text-center w-100 h-100">
                  <span
                    data-purecounter-start="0"
                    data-purecounter-end="232"
                    data-purecounter-duration="1"
                    className="purecounter"
                  ></span>
                  <p>Enterprises</p>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className="stats-item text-center w-100 h-100">
                  <span
                    data-purecounter-start="0"
                    data-purecounter-end="521"
                    data-purecounter-duration="1"
                    className="purecounter"
                  ></span>
                  <p>MSMEs</p>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className="stats-item text-center w-100 h-100">
                  <span
                    data-purecounter-start="0"
                    data-purecounter-end="1453"
                    data-purecounter-duration="1"
                    className="purecounter"
                  ></span>
                  <p>IT Industries</p>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className="stats-item text-center w-100 h-100">
                  <span
                    data-purecounter-start="0"
                    data-purecounter-end="32"
                    data-purecounter-duration="1"
                    className="purecounter"
                  ></span>
                  <p>Corporate</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="services" className="services">
          <div className="container section-title" data-aos="fade-up">
            <h2>Services</h2>
            {/* <p>
              Necessitatibus eius consequatur ex aliquid fuga eum quidem sint
              consectetur velit
            </p> */}
          </div>

          <div className="container">
            <div className="row gy-4">
              <div
                className="col-lg-6 "
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="service-item d-flex">
                  <div className="icon flex-shrink-0">
                    <i className="bi bi-briefcase"></i>
                  </div>
                  <div>
                    <h4 className="title">
                      <a
                        href="https://calendly.com/ca-jaysanghvi24"
                        className="stretched-link"
                      >
                        TAXATION
                      </a>
                    </h4>
                    <p className="description">
                      <ul
                        style={{ listStyle: "none" }}
                        className="dd-box-shadow"
                      >
                        <li>
                          <a href="#">Direct Tax</a>
                        </li>
                        <li>
                          <a href="#">Income Tax</a>
                        </li>
                        <li>
                          <a href="#">Corporate Tax</a>
                        </li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-6 "
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="service-item d-flex">
                  <div className="icon flex-shrink-0">
                    <i className="bi bi-card-checklist"></i>
                  </div>
                  <div>
                    <h4 className="title">
                      <a
                        href="https://calendly.com/ca-jaysanghvi24"
                        className="stretched-link"
                      >
                        INDIRECT TAX
                      </a>
                    </h4>
                    <p className="description">
                      <ul
                        style={{ listStyle: "none" }}
                        className="dd-box-shadow"
                      >
                        <li>
                          <a href="#">GST COMPLIANCE</a>
                        </li>
                        <li>
                          <a href="#">GST RETURNS</a>
                        </li>
                        <li>
                          <a href="#">GST CLASSIFICATIONS</a>
                        </li>
                        <li>
                          <a href="#">GST SEARCH</a>
                        </li>
                        <li>
                          <a href="#">GST REFUND</a>
                        </li>
                        <li>
                          <a href="#">E-way bill cosultancy</a>
                        </li>
                        <li>
                          <a href="#">E- invoice cosultancy</a>
                        </li>
                        <li>
                          <a href="#">GST OPINIONE</a>
                        </li>
                        <li>
                          <a href="#">GST DUE DILIGENCE</a>
                        </li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-6 "
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div className="service-item d-flex">
                  <div className="icon flex-shrink-0">
                    <i className="bi bi-bar-chart"></i>
                  </div>
                  <div>
                    <h4 className="title">
                      <a
                        href="https://calendly.com/ca-jaysanghvi24"
                        className="stretched-link"
                      >
                        Accounting & Bussiness Support
                      </a>
                    </h4>
                    <p className="description">
                      <ul
                        style={{ listStyle: "none" }}
                        className="dd-box-shadow"
                      >
                        <li>
                          <a href="#">Bussiness Support</a>
                        </li>
                        <li>
                          <a href="#">Accounting & Book-kepping</a>
                        </li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-6 "
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <div className="service-item d-flex">
                  <div className="icon flex-shrink-0">
                    <i className="bi bi-binoculars"></i>
                  </div>
                  <div>
                    <h4 className="title">
                      <a
                        href="https://calendly.com/ca-jaysanghvi24"
                        className="stretched-link"
                      >
                        Audit & Assurance
                      </a>
                    </h4>
                    <p className="description">
                      <ul
                        style={{ listStyle: "none" }}
                        className="dd-box-shadow"
                      >
                        <li>
                          <a href="#">Internal Audit</a>
                        </li>
                        <li>
                          <a href="#">Follow up Audit</a>
                        </li>
                        <li>
                          <a href="#">Inventory Audit</a>
                        </li>
                        <li>
                          <a href="#">Revenue Audit</a>
                        </li>
                        <li>
                          <a href="#">Operational Audit</a>
                        </li>
                        <li>
                          <a href="#">Management Audit</a>
                        </li>
                        <li>
                          <a href="#">Audit & Assurance</a>
                        </li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="call-to-action" className="call-to-action">
          <img src={img2} alt="" data-aos="fade-in" />

          <div className="container">
            <div
              className="row justify-content-center"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div className="col-xl-10">
                <div className="text-center">
                  <h3>Call To Action</h3>
                  <p>
                    I cordially invite you to a consultation, where we can delve
                    into a comprehensive discussion about potential
                    collaboration. This conversation will provide us with the
                    opportunity to explore in detail how we can work together to
                    optimize and elevate your financial circumstances. We are
                    committed to tailoring our approach to deliver effective and
                    personalized solutions that align with your specific needs
                    and goals. I eagerly anticipate the opportunity to connect
                    and strategize for your financial success
                  </p>
                  <a
                    className="cta-btn"
                    href="https://calendly.com/ca-jaysanghvi24"
                  >
                    Book a Call
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="testimonials" className="testimonials">
          <div className="container">
            <div className="row align-items-center">
              <div
                className="col-lg-5 info"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <h3>Testimonials</h3>
                <p>
                  Discover what our valued clients have to say about their
                  experiences with us. We take pride in delivering exceptional
                  service and exceeding expectations. Read on to explore
                  firsthand testimonials that highlight the satisfaction and
                  success our clients have achieved with our dedicated team.
                  Your success story could be the next one we proudly showcase!
                </p>
              </div>

              <div className="col-lg-7" data-aos="fade-up" data-aos-delay="200">
                <div className="swiper">
                  {/* <template className="swiper-config">
                {
                "loop": true,
                "speed" : 600,
                "autoplay": {
                "delay": 5000
                },
                "slidesPerView": "auto",
                "pagination": {
                "el": ".swiper-pagination",
                "type": "bullets",
                "clickable": true
                }
                }
              </template> */}
                  <div className="swiper-wrapper">
                    <div className="swiper-slide">
                      <div className="testimonial-item">
                        <div className="d-flex">
                          {/* <img
                            src="assets/img/testimonials/testimonials-1.jpg"
                            className="testimonial-img flex-shrink-0"
                            alt="" */}

                          <div>
                            <h3>TAPAN SHAH</h3>
                            <h4>OWNER</h4>
                          </div>
                        </div>
                        <p>
                          <span>
                            since we started working together and it was
                            probably one of the best decisions I have ever made.
                            Professionalism, honesty, dedication, making things
                            happen, never saying no to anything, finding
                            solutions, thinking out of the box and most
                            important being treated as family, is what I got
                            from working with you`ll. Wishing you`ll all the
                            success you`ll richly deserve."
                          </span>
                        </p>
                      </div>
                    </div>

                    <div className="swiper-slide">
                      <div className="testimonial-item">
                        <div className="d-flex">
                          {/* <img
                            src="assets/img/testimonials/testimonials-2.jpg"
                            className="testimonial-img flex-shrink-0"
                            alt=""
                          /> */}
                          <div>
                            <h3>PARTH SHAH</h3>
                            <h4>PROPRIETOR</h4>
                          </div>
                        </div>
                        <p>
                          <span>
                            After working with JSA I realised the importance of
                            great consultants in business to let you focus in
                            the business. JSA went above and beyond to take care
                            of our compliances, Accounting, Audit and giving us
                            right guidance and advice throughout
                          </span>
                        </p>
                      </div>
                    </div>

                    <div className="swiper-slide">
                      <div className="testimonial-item">
                        <div className="d-flex">
                          {/* <img
                            src="assets/img/testimonials/testimonials-3.jpg"
                            className="testimonial-img flex-shrink-0"
                            alt=""
                          /> */}
                          <div>
                            <h3>Dhyey Shukla</h3>
                            <h4>CEO</h4>
                          </div>
                        </div>
                        <p>
                          <i className="bi bi-quote quote-icon-left"></i>
                          <span>
                            JSA has been an invaluable partner in navigating the
                            complex landscape of financial regulations. Their
                            team's expertise and attention to detail have not
                            only ensured our compliance but also optimized our
                            financial strategies. With JSA, we've found a
                            trusted ally committed to excellence and innovation
                            in the world of finance. Truly, a partner that goes
                            beyond expectations.
                          </span>
                          <i className="bi bi-quote quote-icon-right"></i>
                        </p>
                      </div>
                    </div>

                    <div className="swiper-slide">
                      <div className="testimonial-item">
                        <div className="d-flex">
                          {/* <img
                            src="assets/img/testimonials/testimonials-4.jpg"
                            className="testimonial-img flex-shrink-0"
                            alt=""
                          /> */}
                          <div>
                            <h3>Yash Solanki</h3>
                            <h4>Owner</h4>
                          </div>
                        </div>
                        <p>
                          <i className="bi bi-quote quote-icon-left"></i>
                          <span>
                            JSA has been instrumental in guiding our business
                            through complex financial landscapes with unwavering
                            expertise. Their team's dedication to precision and
                            commitment to excellence sets them apart in the
                            realm of financial services. Partnering with JSA has
                            not only streamlined our accounting processes but
                            has also elevated our financial strategy to new
                            heights.
                          </span>
                          <i className="bi bi-quote quote-icon-right"></i>
                        </p>
                      </div>
                    </div>

                    <div className="swiper-slide">
                      <div className="testimonial-item">
                        <div className="d-flex">
                          {/* <img
                            src="assets/img/testimonials/testimonials-5.jpg"
                            className="testimonial-img flex-shrink-0"
                            alt=""
                          /> */}
                          <div>
                            <h3>Vedang Jani</h3>
                            <h4>Entrepreneur</h4>
                          </div>
                        </div>
                        <p>
                          <i className="bi bi-quote quote-icon-left"></i>
                          <span>
                            "Choosing JSA for our financial needs was a
                            game-changer. Their expertise in navigating complex
                            tax regulations and dedication to client
                            satisfaction is unparalleled. With JSA, we found a
                            trusted partner that not only meets but exceeds our
                            expectations in delivering top-notch financial
                            solutions."
                          </span>
                          <i className="bi bi-quote quote-icon-right"></i>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-pagination"></div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="contact" className="contact">
          <div className="container section-title" data-aos="fade-up">
            <h2>Contact</h2>
            <p>Committed to Your Trust in Financial Matters</p>
          </div>

          <div className="container" data-aos="fade-up" data-aos-delay="100">
            <div className="row gy-4">
              <div className="col-lg-6">
                <div className="row gy-4">
                  <div className="col-md-6">
                    <div
                      className="info-item"
                      data-aos="fade"
                      data-aos-delay="200"
                    >
                      <i className="bi bi-geo-alt"></i>
                      <h3>Address</h3>
                      <p>
                        6th floor, Panchdhara complex, next to grand bhagwati,
                        Sg highway
                      </p>
                      <p>Ahmedabad.</p>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div
                      className="info-item"
                      data-aos="fade"
                      data-aos-delay="300"
                    >
                      <i className="bi bi-telephone"></i>
                      <h3>Call Us</h3>
                      <p>+91 9426825818</p>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div
                      className="info-item"
                      data-aos="fade"
                      data-aos-delay="400"
                    >
                      <i className="bi bi-envelope"></i>
                      <h3>Email Us</h3>
                      <p>ca.jaysanghvi24@gmail.com</p>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div
                      className="info-item"
                      data-aos="fade"
                      data-aos-delay="500"
                    >
                      <i className="bi bi-clock"></i>
                      <h3>Open Hours</h3>
                      <p>Monday - Friday</p>
                      <p>10:00AM - 07:00PM</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <form
                  id="myForm"
                  onClick={handleSubmit}
                  className="php-email-form"
                >
                  <div className="row gy-4">
                    <div className="col-md-6">
                      <input
                        type="text"
                        name="name"
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Your Name"
                        required
                      />
                    </div>

                    <div className="col-md-6 ">
                      <input
                        type="email"
                        onChange={handleChange}
                        className="form-control"
                        name="email"
                        placeholder="Your Email"
                        required
                      />
                    </div>

                    <div className="col-md-12">
                      <input
                        type="text"
                        onChange={handleChange}
                        className="form-control"
                        name="compname"
                        placeholder="Company Name"
                        required
                      />
                    </div>

                    <div className="col-md-12">
                      <textarea
                        className="form-control"
                        name="message"
                        onChange={handleChange}
                        rows="6"
                        placeholder="Message"
                        required
                      ></textarea>
                    </div>

                    <div className="col-md-12 text-center">
                      <div className="loading">Loading</div>
                      <div className="error-message"></div>
                      <div className="sent-message">
                        Thanks for your inquiry! We've got the details - talk
                        soon!
                      </div>

                      <button type="submit">Send Inquiry</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>

      <footer id="footer" className="footer">
        <div className="container footer-top">
          <div className="row gy-4">
            <div className="col-lg-5 col-md-12 footer-about">
              <a href="index.html" className="logo d-flex align-items-center">
                <span>JS&A</span>
              </a>
              <p>Chartered Accountants</p>
              <div className="social-links d-flex mt-4">
                {/* <a href="">
                  <i className="bi bi-twitter"></i>
                </a>
                <a href="">
                  <i className="bi bi-facebook"></i>
                </a>
                <a href="">
                  <i className="bi bi-instagram"></i>
                </a> */}
                <a href="https://www.linkedin.com/in/cajaysanghvi24/">
                  <img width={50} src={imgLinkdin} />
                </a>
              </div>
            </div>

            <div className="col-lg-2 col-6 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <a href="#">Home</a>
                </li>
                <li>
                  <a href="#">About us</a>
                </li>
                <li>
                  <a href="services">Our Services</a>
                </li>
                <li>
                  <a href="#">Blog</a>
                </li>
                <li>
                  <a href="#">Contact Us</a>
                </li>
              </ul>
            </div>

            <div className="col-lg-2 col-6 footer-links">
              <h4>Our Services</h4>
              <ul>
                <li>
                  <a href="services">INDIRECT TAX</a>
                </li>
                <li>
                  <a href="#">GST COMPLIANC</a>
                </li>
                <li>
                  <a href="#">Accounting & Bussiness Support</a>
                </li>
                <li>
                  <a href="#">Audit & Assurance </a>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
              <h4>Contact Us</h4>
              <p>
                6th floor, Panchdhara complex, next to grand bhagwati, Sg
                highway
              </p>
              <p>Ahmedabad.</p>
              <p>Gujarat</p>
              <p className="mt-4">
                <strong>Phone:</strong> <span>+91 9426825818</span>
              </p>
              <p>
                <strong>Email:</strong> <span>ca.jaysanghvi24@gmail.com</span>
              </p>
            </div>
          </div>
        </div>

        <div className="container copyright text-center mt-4">
          <p>
            &copy; <span>Copyright</span>{" "}
            <strong className="px-1">Jay Sanghvi & Associates</strong>{" "}
            <span>All Rights Reserved.</span>
          </p>
          <div className="credits">
            Articulated and Constructed <a>Edge Byte.com</a>
          </div>
        </div>
      </footer>

      <a
        href="#"
        id="scroll-top"
        className="scroll-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short"></i>
      </a>

      {/* <script src="assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
      <script src="assets/vendor/glightbox/js/glightbox.min.js"></script>
      <script src="assets/vendor/purecounter/purecounter_vanilla.js"></script>
      <script src="assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
      <script src="assets/vendor/swiper/swiper-bundle.min.js"></script>
      <script src="assets/vendor/aos/aos.js"></script>
      <script src="assets/vendor/php-email-form/validate.js"></script>

      <script src="assets/js/main.js"></script> */}
    </div>
  );
}

export default Home;
