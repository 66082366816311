// PopupModal.js
import React, { useState } from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const PopupModal = () => {
  const [modalIsOpen, setModalIsOpen] = useState(true);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 1000, // Set a high z-index value
        },
        content: {
          width: '300px',
          height: '200px',
          margin: 'auto',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          zIndex: 1001, // Set a higher z-index value
        },
      }}
    >
      <div>
        <p>For mobile users, please check the "Desktop Site" box.</p>
        <button onClick={closeModal}>Cancel</button>
      </div>
    </Modal>
  );
};

export default PopupModal;
